/* You can add global styles to this file, and also import other style files */
html,body{
    margin: 0;
    padding: 0;
    background-color: #242424;
    /* background-color: #f0f0f0; */
    /* font-family: 'Roboto'; */
    /* background-image: url(assets/img/back.png); */
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-weight: 300;
    background-attachment: fixed;
}

.cursor{
    color: white;
    font-family: sans-serif;
    position: relative;
    top: 1px;
    opacity: 1;
    animation-name: blik;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
.tab{
    display: inline-block;
    width: 30px;
}
.code_padding{
    padding-left: 30px;
}

@keyframes blik {
    0%   {opacity: 1}
    49%   {opacity: 1}
    50%   {opacity: 0}
    99%   {opacity: 0}

}
